.column-flex {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 105px);
}
.container {
  flex: 1;
}
.search-plate {
  background: #fff;
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.search-plate .ant-btn {
  margin-left: 10px;
}
.search-plate .ant-btn-primary {
  background: #4687FE;
}
.search-plate :deep .ant-select {
  width: 200px !important;
  margin-right: 10px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.flex-left {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.flex-left > div {
  display: flex;
  align-items: center;
  margin: 0 20px 15px 0;
}
.flex-left > div > span {
  text-wrap: nowrap;
}
.flex-left > .ant-btn {
  margin-bottom: 15px;
}
.result-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}
.result-item span:nth-child(1) {
  max-width: 90%;
}
