
.column-flex {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 105px);
}

.container {
  flex: 1;
}

.search-plate {
  background: #fff;
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-radius: 5px;

  .ant-btn {
    margin-left: 10px;
  }

  .ant-btn-primary {
    background: #4687FE;
  }

  :deep .ant-select {
    width: 200px !important;
    margin-right: 10px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex-left {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  >div {
    display: flex;
    align-items: center;
    margin: 0 20px 15px 0;

    >span {
      text-wrap: nowrap;
    }
  }

  >.ant-btn {
    margin-bottom: 15px;
  }
}


.result-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;

  span:nth-child(1) {
    max-width: 90%;
  }
}

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;